import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import "./Onboard.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateAadhaarImageBack } from "../../store/actions/HrmsOnboard";
import Constant from "../../utils/constant";
export default function Onboard() {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => state.image);

  const [employeeData, setEmployeeData] = useState({
    employee_id: "",
    title: "Mr",
    first_name: "",
    last_name: "",
    email: "",
    joining_position: " ",
    department: "",
    joining_date: "",
    reporting_manager: "",
    work_location: "",
    employee_type: "",
    shift_timing: "",
    contact_number: "",
    city: "",
    state: "",
    pincode: "",
    country: "",
    emergency_contact_number: "",
    dob: "",
    gender: "",
    marital_status: "",
    highest_qualification: "",
    dgree: "",
    institute_university_name: "",
    year_of_graduation: "2000",
    board_of_education: " ",
    calss_10_percentage: "",
    class_12_percentage: "",
    class_12_stream: "",
    class_10_marksheet: "",
    class_12_marksheet: "",
    highest_qualification_marksheet: "",
    adhar: "",
    pan: "",
    passport: "",
    skill_acquired: "",
    additional_courses: "",
    certification_skill_acquired: "",
    certification_additional_courses: "",
  });

  const [workingCompany, setWorkingCompany] = useState([
    {
      company_name: "",
      job_title: " ",
      start_date: "",
      end_date: "",
      pay_slip: "",
      relieving_letter: "",
      signed_resignation_letter: "",
      offer_letter: "",
    },
  ]);

  const handleInputChange = async (e, section, index = null) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      const formData = new FormData();
      formData.append("documentData", files[0]);

      try {
        const response = await axios.post(
          Constant.BASE_URL + "hrms/addDocuments",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const fileUrl = response.data.photoURL;
        console.log(fileUrl);

        if (section === "employee") {
          setEmployeeData((prevData) => ({
            ...prevData,
            [name]: fileUrl,
          }));
        } else if (section === "job" && index !== null) {
          const updatedCompanies = [...workingCompany];
          updatedCompanies[index][name] = fileUrl;
          setWorkingCompany(updatedCompanies);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file. Please try again.");
      }
    } else {
      if (section === "employee") {
        setEmployeeData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (section === "job" && index !== null) {
        const updatedCompanies = [...workingCompany];
        updatedCompanies[index][name] = value;
        setWorkingCompany(updatedCompanies);
      }
    }
  };

  const addJobSection = () => {
    setWorkingCompany((prevCompanies) => [
      ...prevCompanies,
      {
        company_name: "",
        job_title: "",
        start_date: "",
        end_date: "",
        pay_slip: "",
        relieving_letter: "",
        signed_resignation_letter: "",
        offer_letter: "",
      },
    ]);
  };

  const removeJobSection = (index) => {
    if (workingCompany.length > 1) {
      setWorkingCompany((prevCompanies) =>
        prevCompanies.filter((_, i) => i !== index)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(document.querySelector("form"));

    for (let [name, value] of formData) {
      if (!value) {
        alert(`${name} is required`);
        return;
      }
    }
    // Basic validation
    if (
      // !employeeData.first_name ||
      // !employeeData.last_name ||
      !employeeData.email
    ) {
      alert("Please fill out all required fields.");
      return;
    }
    console.log("Employee Data before submission:", employeeData);
    console.log("Working Company Data before submission:", workingCompany);

    const updatedEmployeeData = {
      employeeData: {
        ...employeeData,
      },
      workingCompany,
    };

    try {
      const response = await fetch(
        Constant.BASE_URL + "hrms/onboardingEmployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedEmployeeData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      alert("Employee added successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to add employee. Please try again.");
    }
  };

  // Handle Redux-based file upload
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      const formData = new FormData();
      formData.append("passport", e.target.files[0]);
      dispatch(updateAadhaarImageBack(formData));
    }
  };

  return (
    <div className="AgentCustomer">
      {/*<!-- Main Body --> */}
      <div className="container-fluid my-2">
        <div className="row">
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div className="py-3">
                  <h4
                    className="text-uppercase text-center"
                    style={{
                      fontFamily: "ErasDemiITC",
                      fontWeight: "normal",
                    }}
                  >
                    Onboard Employee
                  </h4>
                </div>
              </div>
            </div>
            <div className="row py-0">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0 px-3">
                <div className="px-2 rounded-3">
                  <hr className="my-0" />
                </div>
              </div>
            </div>
            <div className="row py-0">
              <div className="multisteps-form">
                <div className="row"></div>

                <div className="row">
                  <div className="col-12 col-lg-12 m-auto">
                    <form className="multisteps-form__form">
                      <div
                        className="multisteps-form__panel rounded js-active"
                        data-animation="scaleIn"
                      >
                        <div
                          className="bg-white p-4 shadow  pb-5 rounded-3"
                          style={{
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between border-bottom pb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Personal information
                              </h4>
                            </div>
                            <div className="bg-white">
                              {/* <button
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditPersonalInfoDetails"
                              >
                                <i className="bi bi-pencil" /> Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="icueSavePersonalInfoDetails"
                              >
                                <i className="bi bi-save" /> Update
                              </button> */}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row pt-4">
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemTitle"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Title
                                </label>
                                <select
                                  value={employeeData.title}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icuePersonalFormCueMemTitle"
                                  name="title"
                                  className="form-select text-uppercase icuePersonalFormInput   bg-white icuePerFormSel text-muted"
                                >
                                  <option value="mr.">mr.</option>
                                  <option value="ms.">ms.</option>
                                  <option value="mrs.">mrs.</option>
                                </select>
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemFname"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  First Name
                                </label>
                                <input
                                  value={employeeData.first_name}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemFname"
                                  name="first_name"
                                  className="form-control text-uppercase icuePersonalFormInput bg-white text-muted"
                                />
                              </div>

                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemLName"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Last name
                                </label>
                                <input
                                  value={employeeData.last_name}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemLName"
                                  name="last_name"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="abc"
                                />
                              </div>
                              <div>
                                <hr />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemRegAdd"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  City
                                </label>
                                <input
                                  value={employeeData.city}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemRegAdd"
                                  name="city"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="abc"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  State
                                </label>
                                <input
                                  value={employeeData.state}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id=""
                                  name="state"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="abc"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemPin"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Pincode
                                </label>
                                <input
                                  value={employeeData.pincode}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemPin"
                                  name="pincode"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue={12211}
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Country
                                </label>
                                <input
                                  value={employeeData.country}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id=""
                                  name="country"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="abc"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemEmail"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  E-mail
                                </label>
                                <input
                                  value={employeeData.email}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="email"
                                  id=""
                                  name="email"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="xyz@gmail.com"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                <label
                                  htmlFor="icuePersonalFormCueMemPhoneNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Contact Number
                                </label>
                                <input
                                  value={employeeData.contact_number}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemPhoneNo"
                                  name="contact_number"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="xxxxxxxxxx"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 pt-3">
                                <label
                                  htmlFor="icuePersonalFormCueMemMobNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Emergency Contact Number
                                </label>
                                <input
                                  value={employeeData.emergency_contact_number}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icuePersonalFormCueMemMobNo"
                                  name="emergency_contact_number"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="xxxxxxxxxx"
                                />
                              </div>

                              <div>
                                <hr />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemPanNo"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Date Of Birth
                                </label>
                                <input
                                  value={
                                    employeeData.dob
                                      ? new Date(employeeData.dob)
                                          .toISOString()
                                          .slice(0, 10)
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="date"
                                  id="icuePersonalFormCueMemPanNo"
                                  name="dob"
                                  className="form-control text-uppercase icuePersonalFormInput   bg-white text-muted"
                                  defaultValue="alwpg5809l"
                                />
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor=""
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Gender
                                </label>
                                <select
                                  value={employeeData.gender}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id=""
                                  name="gender"
                                  className="form-select text-capitalize icuePersonalFormInput   bg-white icuePerFormSel text-muted"
                                >
                                  <option value="selft employed">Male</option>
                                  <option value="self">Female</option>
                                  <option value="employed">Other</option>
                                </select>
                              </div>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                <label
                                  htmlFor="icuePersonalFormCueMemProfession"
                                  className="form-label icueTextPrimary icuePersonalInfoLabel"
                                >
                                  Marital Status
                                </label>
                                <select
                                  value={employeeData.marital_status}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icuePersonalFormCueMemProfession"
                                  name="marital_status"
                                  className="form-select text-capitalize icuePersonalFormInput   bg-white icuePerFormSel text-muted"
                                >
                                  <option value="selft employed">Yes</option>
                                  <option value="self">No</option>
                                </select>
                              </div>

                              <div>
                                <hr />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Educational Information
                              </h4>
                            </div>
                            <div className="bg-white">
                              {/* <button
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditGenInfoDetails"
                              >
                                <i className="bi bi-pencil" /> Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary text-white"
                                id="icueSaveGenInfoDetails"
                              >
                                <i className="bi bi-save" /> Update
                              </button> */}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormCueMember"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Highest Qualification
                                </label>
                                <select
                                  value={employeeData.highest_qualification}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icueGenFormCueMember"
                                  name="highest_qualification"
                                  className="form-select  text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                >
                                  <option value="HighSchool">
                                    High School
                                  </option>
                                  <option value="Bachelors">Bachelors</option>
                                  <option value="Masters">Masters</option>
                                  <option value="PhD">PhD</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Degree
                                </label>
                                <input
                                  // value={employeeData.dgree}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="dgree"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Institution/University Name
                                </label>
                                <input
                                  value={employeeData.institute_university_name}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="institute_university_name"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Year of Graduation
                                </label>
                                <input
                                  value={employeeData.year_of_graduation}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="year_of_graduation"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Board of Education
                                </label>
                                <select
                                  value={employeeData.board_of_education}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icueGenFormAlreadyInsured"
                                  name="board_of_education"
                                  className="form-select text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                >
                                  <option value="no">HBSE</option>
                                  <option value="yes">CBSE</option>
                                  <option value="yes">ICSE</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 10 Percentage
                                </label>
                                <input
                                  value={employeeData.calss_10_percentage}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="calss_10_percentage"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue="%"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 12 Percentage
                                </label>
                                <input
                                  value={employeeData.class_12_percentage}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="class_12_percentage"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue="%"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Class 12 Stream
                                </label>
                                <select
                                  value={employeeData.class_12_stream}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icueGenFormAlreadyInsured"
                                  name="class_12_stream"
                                  className="form-select text-uppercase icueGenFormInput   bg-white icueGenFormSel text-muted"
                                >
                                  <option value="Commerce">Commerce</option>
                                  <option value="Science">Science</option>
                                  <option value="Arts">Arts</option>
                                </select>
                              </div>
                              <br />

                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Class 10 Marksheet
                                </label>
                                <input
                                  // value={employeeData.calss_10_marksheet}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="class_10_marksheet"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Class 12 Marksheet
                                </label>
                                <input
                                  // value={employeeData.class_12_marksheet}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="class_12_marksheet"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Upload Highest Qualification Marksheet
                                </label>
                                <input
                                  // value={
                                  //   employeeData.highest_qualification_marksheet
                                  // }
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="highest_qualification_marksheet"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Skills Acquired
                                </label>
                                <input
                                  value={employeeData.skill_acquired}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="skill_acquired"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Certification
                                </label>
                                <input
                                  // value={
                                  //   employeeData.certification_skill_acquired
                                  // }
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="certification_skill_acquired"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Additional Courses
                                </label>
                                <input
                                  value={employeeData.additional_courses}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueGenFormTypeInsurance"
                                  name="additional_courses"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                  defaultValue=" "
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueGenFormTypeInsurance"
                                  className="form-label icueTextPrimary icueGenInfoLabel mt-3"
                                >
                                  Certification
                                </label>
                                <input
                                  // value={
                                  //   employeeData.certification_additional_courses
                                  // }
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueGenFormTypeInsurance"
                                  name="certification_additional_courses"
                                  className="form-control text-uppercase icueGenFormInput   bg-white text-muted"
                                />
                              </div>
                            </div>
                            <hr></hr>{" "}
                          </div>
                        </div>

                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Previous Employment Details
                              </h4>
                            </div>
                            <div className="bg-white">
                              {/* <button
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditGennInfoDetails"
                                style={{ color: "#3a3285" }}
                              >
                                <i className="bi bi-pencil" /> Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary text-white"
                                id="icueSaveGennInfoDetails"
                              >
                                <i className="bi bi-save" /> Update
                              </button> */}
                            </div>
                          </div>

                          {workingCompany.map((job, index) => (
                            <div
                              key={index}
                              className="multisteps-form__content"
                              style={{ fontFamily: "Calibri" }}
                            >
                              <div className="bg-white">
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  type="button"
                                  className="btn btn-transparent icueTextInfoLight"
                                  id="icueEditGennInfoDetails"
                                  onClick={() => removeJobSection(index)}
                                  style={{
                                    float: "right",
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              </div>
                              <div className="row">
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`companyName-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Company Name
                                  </label>
                                  <input
                                    type="text"
                                    id={`companyName-${index}`}
                                    name="company_name"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    value={job.company_name}
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`jobTitle-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Job Title
                                  </label>
                                  <input
                                    type="text"
                                    id={`jobTitle-${index}`}
                                    name="job_title"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    value={job.job_title}
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`startDate-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Start Date
                                  </label>
                                  <input
                                    type="date"
                                    id={`startDate-${index}`}
                                    name="start_date"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    value={
                                      workingCompany[index].start_date // Corrected here
                                        ? new Date(
                                            workingCompany[index].start_date
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>

                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`endDate-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    End Date
                                  </label>
                                  <input
                                    type="date"
                                    id={`endDate-${index}`}
                                    name="end_date"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    value={
                                      workingCompany[index].end_date
                                        ? new Date(
                                            workingCompany[index].end_date
                                          )
                                            .toISOString()
                                            .slice(0, 10)
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`payslip-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Upload Payslip
                                  </label>
                                  <input
                                    type="file"
                                    id={`payslip-${index}`}
                                    name="payslip"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`relievingLetter-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Upload Relieving Letter
                                  </label>
                                  <input
                                    type="file"
                                    id={`relievingLetter-${index}`}
                                    name="relievingLetter"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`resignationLetter-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Upload Signed Resignation Letter
                                  </label>
                                  <input
                                    type="file"
                                    id={`resignationLetter-${index}`}
                                    name="resignationLetter"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                  <label
                                    htmlFor={`offerLetter-${index}`}
                                    className="form-label icueTextPrimary icueGennInfoLabel mt-3"
                                  >
                                    Upload Offer Letter
                                  </label>
                                  <input
                                    type="file"
                                    id={`offerLetter-${index}`}
                                    name="offerLetter"
                                    className="form-control text-uppercase icueGennFormInput   bg-white text-muted"
                                    onChange={(e) =>
                                      handleInputChange(e, "job", index)
                                    }
                                  />
                                </div>
                              </div>

                              <hr />
                            </div>
                          ))}

                          {/* Add More Company Button */}
                          <button
                            type="button"
                            onClick={addJobSection}
                            style={{
                              marginTop: "20px",
                              marginLeft: "20px",
                              padding: "10px 20px",
                              backgroundColor: "#007bff",
                              color: "#fff",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                          >
                            Add More Company
                          </button>
                        </div>
                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Employment Details
                              </h4>
                            </div>
                            <div className="bg-white">
                              {/* <button
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditEmpInfoDetails"
                              >
                                <i className="bi bi-pencil" /> Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary text-white"
                                id="icueSaveEmpInfoDetails"
                              >
                                <i className="bi bi-save" /> Update
                              </button> */}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsured"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Employee ID
                                </label>
                                <input
                                  value={employeeData.employee_id}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueEmpFormTypeInsured"
                                  name="employee_id"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  defaultValue="55555"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Position
                                </label>
                                <input
                                  value={employeeData.joining_position}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueEmpFormTypeInsurance"
                                  name="joining_position"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  defaultValue="Manager"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Department
                                </label>
                                <input
                                  value={employeeData.department}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueEmpFormTypeInsurance"
                                  name="department"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  defaultValue="IT"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Reporting Manager
                                </label>
                                <input
                                  value={employeeData.reporting_manager}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueEmpFormTypeInsurance"
                                  name="reporting_manager"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  defaultValue=" abc"
                                />
                              </div>

                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Joining Date
                                </label>
                                <input
                                  value={
                                    employeeData.joining_date
                                      ? new Date(employeeData.joining_date)
                                          .toISOString()
                                          .slice(0, 10)
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="date"
                                  id="icueEmpFormTypeInsurance"
                                  name="joining_date"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  //
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Work Location
                                </label>
                                <input
                                  value={employeeData.work_location}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="text"
                                  id="icueEmpFormTypeInsurance"
                                  name="work_location"
                                  className="form-control text-uppercase icueEmpFormInput   bg-white text-muted"
                                  defaultValue="INdia"
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormAlreadyInsured"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Employment Type
                                </label>
                                <select
                                  value={employeeData.employee_type}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icueEmpFormAlreadyInsured"
                                  name="employee_type"
                                  className="form-select text-uppercase icueEmpFormInput   bg-white icueEmpFormSel text-muted"
                                >
                                  <option value="FullTimeEmployee">
                                    Full Time Employee
                                  </option>
                                  <option value="Trainee">Trainee</option>
                                  <option value="Internship">Internship</option>
                                </select>
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueEmpFormTypeInsurance"
                                  className="form-label icueTextPrimary icueEmpInfoLabel mt-3"
                                >
                                  Shift Time
                                </label>
                                <select
                                  value={employeeData.shift_timing}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  id="icueEmpFormAlreadyInsured"
                                  name="shift_timing"
                                  className="form-select text-uppercase icueEmpFormInput   bg-white icueEmpFormSel text-muted"
                                >
                                  <option value="morning">Morning</option>
                                  <option value="evening">Evening</option>
                                  <option value="night">Night</option>
                                </select>
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        </div>

                        <div
                          className="bg-white shadow p-4 pb-5 rounded-3"
                          style={{
                            marginTop: "4rem",
                            marginLeft: "0.8rem",
                            marginRight: "0.8rem",
                          }}
                        >
                          <div className="d-flex justify-content-between pb-4 border-bottom py-0 mb-3">
                            <div className="bg-white">
                              <h4
                                className="text-capitalize icueTextPrimary"
                                style={{ fontFamily: "ErasDemiITC" }}
                              >
                                Document Verification
                              </h4>
                            </div>
                            <div className="bg-white">
                              {/* <button
                                type="button"
                                className="btn btn-transparent icueTextInfoLight"
                                id="icueEditDocInfoDetails"
                              >
                                <i className="bi bi-pencil" /> Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary text-white"
                                id="icueSaveDocInfoDetails"
                              >
                                <i className="bi bi-save" /> Update
                              </button> */}
                            </div>
                          </div>
                          <div
                            className="multisteps-form__content"
                            style={{ fontFamily: "Calibri" }}
                          >
                            <div className="row">
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload Aadhar Card
                                </label>
                                <input
                                  // value={employeeData.adhar}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueDocFormTypeInsurance"
                                  name="adhar"
                                  className="form-control text-uppercase icueDocFormInput   bg-white text-muted"
                                  required
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload PAN Card
                                </label>
                                <input
                                  // value={employeeData.pan}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueDocFormTypeInsurance"
                                  name="pan"
                                  className="form-control text-uppercase icueDocFormInput   bg-white text-muted"
                                  required
                                  defaultValue=""
                                />
                              </div>
                              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                                <label
                                  htmlFor="icueDocFormTypeInsurance"
                                  className="form-label icueTextPrimary icueDocInfoLabel mt-3"
                                >
                                  Upload Passport
                                </label>
                                <input
                                  // value={employeeData.passport}
                                  onChange={(e) =>
                                    handleInputChange(e, "employee")
                                  }
                                  type="file"
                                  id="icueDocFormTypeInsurance"
                                  name="passport"
                                  className="form-control text-uppercase icueDocFormInput   bg-white text-muted"
                                  defaultValue=""
                                />
                                {/* <div>
                                  <input
                                    type="file"
                                    onChange={handleFileChange}
                                    name="passport"
                                  />
                                  {loading && <p>Uploading...</p>}
                                  {data && (
                                    <p>Upload successful: {data.message}</p>
                                  )}
                                  {error && <p>Error: {error}</p>}
                                </div> */}
                              </div>
                            </div>
                            <hr></hr>
                          </div>
                        </div>
                        <button
                          className="signup-btn"
                          style={{
                            backgroundColor: "#007bff",
                            width: "270px",
                            height: "50px",
                            border: "none",
                            borderRadius: "25px",

                            color: "#fff",
                            fontWeight: "normal",
                            margin: " 20px 0",
                            fontFamily: "ERASDEMIITC",
                            fontSize: "1rem",
                          }}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Add Employee
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
