import { Accordion } from "react-bootstrap";
import Header from "../components/Header";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import {
  uploadProfile,
  updateAdhaarImagesBack,
  updateAdhaarImagesFront,
  updatePan,
  updateEducationCert,
  updateBankCopy, 
} from "../store/actions/Image";
import { addPos } from "../store/actions/personalDetails";
import { alertShow } from "../store/actions/alert";
import PersonalDetailsForm from "../components/PersonalDetailsForm";
import { useNavigate } from "react-router-dom";
import ContactDetailsForm from "../components/ContactDetailsForm";
import EducationDetailsFrom from "../components/EducationDetailsFrom";
import BankDetailsForm from "../components/BankDetailsForm";
import BranchForm from "../components/BranchForm";
import Loading from "../components/Loading";
import { checkImgValidation } from "../utils/methods";

const AddPOS = () => {
  const fname_regex = /^[a-zA-Z ]+$/;
  const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const mobile_regex = /^[0-9\b]+$/;

  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const [personalDetail, setPersonalDetail] = useState({
    firstName: "",
    email: "",
    lastName: "",
    mobile: "",
    middleName: "",
    Adhaar_no: "",
    pan: "",
    addressLine1: "",
    addressLine2: "",
    landmark: "",
    othersIfAny: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    bankcopyName: "",
    isChecked: false,
    branchManager: "",
    assignedBranch: "",
  });

  const [accountFile, setAccountFile] = useState([]);
  const [aadharFile, setAaadharFile] = useState([]);
  const [aadharFileBack, setAaadharFileBack] = useState([]);
  const [photoFile, setPhotoFile] = useState([]);
  const [chequeFile, setChequeFile] = useState([]);
  const [offersFile, setOffersFile] = useState([]);
  const [cnicFile, setCnicFile] = useState([]);
  const [checked, setChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectState, setSelectState] = useState("");
  const [selectCity, setSelectCity] = useState("");
  const [selectPinCode, setSelectPinCode] = useState("");
  const [photoLoad, setphotoLoad] = useState(false);
  const [AadhaarLoad, setAadhaarLoad] = useState(false);
  const [panLoad, setpanLoad] = useState(false);
  const [eduload, seteduload] = useState(false);
  const [bankLoad, setbankLoad] = useState(false);
  const [validtion, setvalidtion] = useState(false);
  const [AadhaarLoadBack, setAadhaarLoadBack] = useState(false);
  const [selectQualification, setSelectQualification] = useState("");
  const navigate = useNavigate();
  const validate = (values) => {
    const errors = {};
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const fname_regex = /^[a-zA-Z ]+$/;
    const mobile_regex = /^[0-9\b]+$/;
    const pan_regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

    if (!values.firstName) {
      errors.firstName = "First Name is Required!";
    } else if (!fname_regex.test(values.firstName)) {
      errors.firstName = "Enter Valid First Name!";
    }
    if (values.email === "") {
      errors.email = "Email is Required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "Enter Valid Email!";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile No. is Required";
    } else if (values.mobile.length < 10) {
      errors.mobile = "Mobile No. must be 10 Digits";
    } else if (!mobile_regex.test(values.mobile)) {
      errors.mobile = "Enter valid Mobile No.";
    }
    if (values.Adhaar_no === "") {
      errors.Adhaar_no = "Aadhaar No. is Required";
    } else if (
      !mobile_regex.test(values.Adhaar_no) ||
      values.Adhaar_no.length !== 12 ||
      values.Adhaar_no.startsWith("0") ||
      values.Adhaar_no.startsWith("1")
    ) {
      errors.Adhaar_no = "Enter valid Aadhaar No.";
    }
    if (!values.mobile) {
      errors.mobile = "Mobile is required!";
    }
    if (!values.addressLine1) {
      errors.addressLine1 = "Address is required!";
    }
    if (!photoFile.length > 0) {
      errors.photo = "Photo is required!";
    }
    if (!offersFile.length > 0) {
      errors.offer = "Education Proof is required!";
    }
    if (!aadharFile.length > 0) {
      errors.aadharFile = "Aadhaar Front Image is required!";
    }
    if (!aadharFileBack.length > 0) {
      errors.aadharFileBack = "Aadhaar Back Image is required!";
    }
    if (!cnicFile.length > 0) {
      errors.cnicFile = "Pan Image is required!";
    }
    if (selectState === "") {
      errors.state = "State is required!";
    }
    if (selectCity === "") {
      errors.city = "City is required!";
    }
    if (selectPinCode === "") {
      errors.pincode = "pincode is required!";
    }
    if (selectQualification === "") {
      errors.selectQualification = "Education Qualification is required!";
    }
    if (values.pan === "") {
      errors.pan = "Pan No. is required!";
    } else if (!pan_regex.test(values.pan)) {
      errors.pan = "Enter valid Pan No.";
    }
    if (values.assignedBranch === "") {
      errors.assignedBranch = "Branch is required!";
    }
    if (values.branchManager === "") {
      errors.branchManager = "Manager is required!";
    }
    if (!values.isChecked) {
      errors.isChecked = `Kindly accepts the terms and conditions to submit`;
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setvalidtion(true);
    setFormErrors(validate(personalDetail));
    if (Object.keys(validate(personalDetail)).length === 0) {
      const data = {
        firstName: personalDetail.firstName,
        middleName: personalDetail.middleName,
        lastName: personalDetail.lastName,
        mobileNo: personalDetail.mobile,
        emailId: personalDetail.email,
        pancardNo: personalDetail.pan,
        addressLine1: personalDetail.addressLine1,
        addressLine2: personalDetail.addressLine2,
        landmark: personalDetail.landmark,
        state: selectState,
        city: selectCity,
        pincode: selectPinCode,
        educationalQualification: selectQualification,
        othersIfAny: personalDetail.othersIfAny,
        bankName: personalDetail.bankName,
        accountHolderName: `${personalDetail.firstName} ${personalDetail.middleName} ${personalDetail.lastName}`,
        accountNumber: parseInt(personalDetail.accountNumber),
        ifscCode: personalDetail.ifscCode,
        bankcopyName: personalDetail.bankcopyName,
        aadhaarNo: parseInt(personalDetail.Adhaar_no),
        photoUrl: photoFile[0],
        aadhaarImageFront: aadharFile[0],
        aadhaarImageBack: aadharFileBack[0],
        pancardImageUrl: cnicFile[0],
        eduProofImageUrl1: offersFile[0],
        eduProofImageUrl2: offersFile[1],
        bankCopyImageUrl: chequeFile[0],
        branchManager: personalDetail.branchManager,
        assignedBranch: personalDetail.assignedBranch,
      };
      addPos(data)
        .then((res) => {
          res?.status === 0 && toast.error(res?.message);
          if (res?.data?.errors) {
            const data = res.data.errors;
            data.forEach((element) => {
              toast.error(element.msg);
            });
          }
          if (res?.status === 1) {
            toast.success("Pos added successfully.");
            navigate("/pos/all");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      toast.error("You have some errors in the form. Please re-check!");
    }
  };

  const personalchk = (sectionname, e) => {
    validtion && setFormErrors(validate(personalDetail));
    if (sectionname === "firstName" || sectionname === "lastName") {
      if (e.target.value !== "" && !fname_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only alphabets are allowed!" });
      } else {
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname === "email") {
      if (e.target.value !== "" && !email_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Enter Valid Email!" });
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      } else {
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname === "mobile") {
      if (e.target.value !== "" && !mobile_regex.test(e.target.value)) {
        setErrors({ ...errors, [sectionname]: "Only Numbers are allowed!" });
      } else {
        e.target.value !== "e" &&
          e.target.value.length <= 10 &&
          setPersonalDetail({
            ...personalDetail,
            [sectionname]: e.target.value,
          });
        setErrors({ ...errors, [sectionname]: "" });
      }
    }
    if (sectionname === "Adhaar_no" && e.target.value.length <= 12) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "pan") {
      e.target.value.length <= 11 &&
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "assignedBranch") {
      e.target.value.length > 0 &&
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (sectionname === "branchManager") {
      e.target.value.length > 0 &&
        setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
      setErrors({ ...errors, [sectionname]: "" });
    }
    if (
      sectionname === "addressLine1" ||
      sectionname === "addressLine2" ||
      sectionname === "landmark" ||
      sectionname === "othersIfAny"
    ) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
    if (
      sectionname === "middleName" ||
      sectionname === "bankName" ||
      sectionname === "accountHolderName" ||
      sectionname === "accountNumber" ||
      sectionname === "ifscCode" ||
      sectionname === "bankcopyName"
    ) {
      setPersonalDetail({ ...personalDetail, [sectionname]: e.target.value });
    }
  };

  const uploadSingleFile = (e, field) => {
    validtion && setFormErrors(validate(personalDetail));
    if (checkImgValidation(e?.target?.files?.[0]) === false) {
      return;
    }
    if (field === "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
    }
    if (field === "Aadhaar") {
      if (e.target.files.length) {
        if (!aadharFile.length) {
          setAadhaarLoad(true);
          const formData = new FormData();
          formData.append("aadhaarImageFront", e.target.files[0]);

          updateAdhaarImagesFront(formData)
            .then((res) => {
              if (res.status === 0) {
                setErrors({ ...errors, Aadhaar: res?.message });
                setAadhaarLoad(false);
              } else {
                setAadhaarLoad(false);
                setAaadharFile([...aadharFile, res.aadhaarImageFront]);
                setErrors({ ...errors, Aadhaar: "" });
              }
            })
            .catch((error) => {
              setAadhaarLoad(false);
              setErrors({ ...errors, Aadhaar: error.message });
            });
        }
      }
    }
    if (field === "AadhaarBack") {
      if (e.target.files.length) {
        setAadhaarLoadBack(true);
        const formData = new FormData();
        formData.append("aadhaarImageBack", e.target.files[0]);

        updateAdhaarImagesBack(formData)
          .then((res) => {
            if (res.status === 0) {
              setErrors({ ...errors, Aadhaar: res?.message });
              setAaadharFileBack(false);
            } else {
              setAadhaarLoadBack(false);
              setAaadharFileBack([...aadharFileBack, res.aadhaarImageBack]);
              setErrors({ ...errors, Aadhaar: "" });
            }
          })
          .catch((error) => {
            setAadhaarLoadBack(false);
            setErrors({ ...errors, Aadhaar: error.message });
          });
      }
    }
    if (field === "Account") {
      setAccountFile([...accountFile, URL.createObjectURL(e.target.files[0])]);
    }
    if (field === "Photo") {
      setphotoLoad(true);
      const formData = new FormData();
      formData.append("photo", e.target.files[0]);
      uploadProfile(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, photo: res?.message });
            setphotoLoad(false);
          } else {
            setphotoLoad(false);
            setPhotoFile([...photoFile, res.photoUrl]);
          }
        })
        .catch((error) => {
          alertShow("err", error.message);
          setphotoLoad(false);
          setErrors({ ...errors, photo: error.message });
        });
    }
    if (field === "Offers") {
      seteduload(true);
      const formData = new FormData();
      formData.append("EduCert", e.target.files[0]);
      updateEducationCert(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, eduProofImag: res?.message });
            seteduload(false);
          } else {
            seteduload(false);
            setOffersFile([...offersFile, res.eduProofImageUrl1]);
          }
        })
        .catch((error) => {
          seteduload(false);
          setErrors({ ...errors, eduProofImag: error.message });
        });
    }
    if (field === "CNIC") {
      setpanLoad(true);
      const formData = new FormData();
      formData.append("pancard", e.target.files[0]);
      updatePan(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, cnic: res?.message });
            setpanLoad(false);
          } else {
            setpanLoad(false);
            setCnicFile([...cnicFile, res.pancardImageUrl]);
          }
        })
        .catch((error) => {
          setpanLoad(false);
          setErrors({ ...errors, cnic: error.message });
        });
    }
    if (field === "Cheque") {
      setbankLoad(true);
      const formData = new FormData();
      formData.append("bankCopy", e.target.files[0]);
      updateBankCopy(formData)
        .then((res) => {
          if (res.status === 0) {
            setErrors({ ...errors, updateBank: res?.message });
            setbankLoad(false);
          } else {
            setbankLoad(false);
            setChequeFile([...chequeFile, res.bankCopyImageUrl]);
          }
        })
        .catch((error) => {
          setbankLoad(false);
          setErrors({ ...errors, updateBank: error.message });
        });
    }
  };

  const deleteFile = (e, field) => {
    if (field === "Account") {
      const s = accountFile.filter((item, index) => index !== e);
      setAccountFile(s);
    }
    if (field === "Aadhaar") {
      const s = aadharFile.filter((item, index) => index !== e);
      setAaadharFile(s);
    }
    if (field === "AadhaarBack") {
      const s = aadharFileBack.filter((item, index) => index !== e);
      setAaadharFileBack(s);
    }
    if (field === "Photo") {
      const s = photoFile.filter((item, index) => index !== e);
      setPhotoFile(s);
    }
    if (field === "Offers") {
      const s = offersFile.filter((item, index) => index !== e);
      setOffersFile(s);
    }
    if (field === "CNIC") {
      const s = cnicFile.filter((item, index) => index !== e);
      setCnicFile(s);
    }
    if (field === "Cheque") {
      const s = chequeFile.filter((item, index) => index !== e);
      setChequeFile(s);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);

  return (
    <div className="pos">
      <Header title="Add New POS" />
      {show ? (
        <div className="p-4 bg-white shadow rounded-3">
          <h4 className="mb-3 title">Please Complete Your Details</h4>
          <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Personal Details</Accordion.Header>
              <Accordion.Body>
                <PersonalDetailsForm
                  personalchk={personalchk}
                  uploadSingleFile={uploadSingleFile}
                  deleteFile={deleteFile}
                  aadharFile={aadharFile}
                  personalDetail={personalDetail}
                  errors={errors}
                  photoFile={photoFile}
                  formErrors={formErrors}
                  cnicFile={cnicFile}
                  photoLoad={photoLoad}
                  AadhaarLoad={AadhaarLoad}
                  aadharFileBack={aadharFileBack}
                  panLoad={panLoad}
                  AadhaarLoadBack={AadhaarLoadBack}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Contact Details</Accordion.Header>
              <Accordion.Body>
                <ContactDetailsForm
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  formErrors={formErrors}
                  errors={errors}
                  selectState={selectState}
                  setSelectState={setSelectState}
                  selectCity={selectCity}
                  setSelectCity={setSelectCity}
                  selectPinCode={selectPinCode}
                  setSelectPinCode={setSelectPinCode}
                  cnicFile={cnicFile}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Education Details</Accordion.Header>
              <Accordion.Body>
                <EducationDetailsFrom
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  formErrors={formErrors}
                  errors={errors}
                  selectQualification={selectQualification}
                  setSelectQualification={setSelectQualification}
                  eduload={eduload}
                  offersFile={offersFile}
                  uploadSingleFile={uploadSingleFile}
                  deleteFile={deleteFile}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Bank Details</Accordion.Header>
              <Accordion.Body>
                <BankDetailsForm
                  personalchk={personalchk}
                  personalDetail={personalDetail}
                  bankLoad={bankLoad}
                  uploadSingleFile={uploadSingleFile}
                  deleteFile={deleteFile}
                  chequeFile={chequeFile}
                  formErrors={formErrors}
                  errors={errors}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <BranchForm
            personalchk={personalchk}
            personalDetail={personalDetail}
            formErrors={formErrors}
            errors={errors}
          />
          <div className="row row-cols-1 row-cols-lg-1 ">
            <div className="col d-flex align-items-center">
              <input
                className="me-2"
                type="checkbox"
                onChange={() => {
                  setChecked(!checked);
                  setPersonalDetail({
                    ...personalDetail,
                    isChecked: !personalDetail.isChecked,
                  });
                }}
              />
              <span style={{ fontSize: "0.9rem" }}>
                I Hereby declare that above submitted details are valid and
                submitted by myself only.
              </span>
            </div>
            {!checked && <p className="p-error">{formErrors.isChecked}</p>}
          </div>
          <div className="row row-cols-1 row-cols-lg-1 mt-3 form d-flex justify-content-center align-items-center w-100">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default AddPOS;
