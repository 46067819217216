import { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Constant from "../../utils/constant";
export default function AddMemberModal({ show, handleClose, refreshData }) {
  const styles = {
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: "0.25rem",
    border: "1px solid #6c757d",
    backgroundColor: "#3a3285",
    color: "white",
    cursor: "pointer",
    transition:
      "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
  };
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    empName: "",
    role: "",
    department: "",
    joiningDate: "",
  });
  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.post(
          Constant.BASE_URL + "hrms/listDepartment?page&limit"
        );
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Fetch roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.post(
          Constant.BASE_URL + "hrms/listRoles?page&limit"
        );
        setRoles(response.data.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePermissionChange = (permissionId) => {
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.includes(permissionId)
        ? prevPermissions.filter((id) => id !== permissionId)
        : [...prevPermissions, permissionId]
    );
  };

  const handleSendInvite = async () => {
    if (!validateEmail(formData.email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (!formData.role || !formData.department) {
      alert("Please select both a role and a department.");
      return;
    }

    setEmailError("");

    const requestData = {
      memberData: {
        member_name: formData.empName,
        member_email: formData.email,
        role_id: formData.role,
        department_id: formData.department,
        joining_date:
          formData.joiningDate || new Date().toISOString().split("T")[0],
      },
      memberPermission: selectedPermissions.map((perm) => ({
        permission_id: perm,
      })),
    };

    try {
      await axios.post(Constant.BASE_URL + "hrms/addMember", requestData);
      alert("Member added successfully!");

      setFormData({
        email: "",
        empName: "",
        role: "",
        department: "",
        joiningDate: "",
      });
      setSelectedPermissions([]);
      handleClose();
      refreshData();
    } catch (error) {
      console.error("Error adding member:", error);
      alert("Failed to add member. Please try again.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Enter Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={!!emailError}
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                name="empName"
                value={formData.empName}
                onChange={handleInputChange}
                placeholder="Employee Name"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Select
                name="department"
                value={formData.department}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Department
                </option>
                {departments.map((dept) => (
                  <option key={dept.department_id} value={dept.department_id}>
                    {dept.department_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Role
                </option>
                {roles.map((role) => (
                  <option key={role.role_id} value={role.role_id}>
                    {role.role_name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Permissions:</Form.Label>
            <Row>
              <Col md={6}>
                <Form.Check
                  type="checkbox"
                  label="Notify new Users By Email"
                  onChange={() => handlePermissionChange(1)}
                />
              </Col>
              <Col md={6}>
                <Form.Check
                  type="checkbox"
                  label="Can Manage Users"
                  onChange={() => handlePermissionChange(2)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <button style={styles} onClick={handleSendInvite}>
          Add Member
        </button>
      </Modal.Footer>
    </Modal>
  );
}
