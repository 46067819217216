import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OnboardList.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Constant from "../../utils/constant";
export default function ListMembers() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  useEffect(() => {
    const fetchData = async (page) => {
      setLoading(true);
      try {
        const response = await axios.post(
          Constant.BASE_URL +
            `hrms/getEmployeeList?page=${page}&limit=${itemsPerPage}`
        );
        const data = response.data;

        if (data && data.data) {
          setData(data.data);
          setFilteredData(data.data);

          const totalRecords = data.total || 0;
          const calculatedTotalPages = Math.ceil(totalRecords / itemsPerPage);

          setTotalPages(calculatedTotalPages > 0 ? calculatedTotalPages : 1);
        } else {
          setData([]);
          setFilteredData([]);
          setError("No data found.");
          setTotalPages(1);
        }
      } catch (err) {
        console.error(err);
        setError("Failed to load data.");
        setData([]);
        setFilteredData([]);
        setTotalPages(1);
      } finally {
        setLoading(false);
      }
    };

    fetchData(currentPage);
  }, [currentPage]);

  const handleSearch = (e) => {
    const query = e.target.value.trim().toLowerCase();
    setSearchQuery(query);

    // If the search query is empty, reset to the original data
    if (!query) {
      setFilteredData(data);
      return;
    }

    // Filter data based on search query
    const filtered = data.filter((member) => {
      // Safely extract employee details with default fallback
      const firstName = member?.employeeData?.first_name?.toLowerCase() || "";
      const lastName = member?.employeeData?.last_name?.toLowerCase() || "";
      const email = member?.employeeData?.email?.toLowerCase() || "";
      const employeeId =
        member?.employeeData?.employee_id?.toString().toLowerCase() || "";

      const fullName = `${firstName} ${lastName}`.trim(); // Combine first and last name

      return (
        fullName.includes(query) ||
        email.includes(query) ||
        employeeId.includes(query)
      );
    });

    setFilteredData(filtered);
  };

  const handleViewMore = (employee) => {
    setSelectedEmployee(employee);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedEmployee(null);
    setShowModal(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const handleDelete = async (employeeId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this employee?"
    );
    if (!confirmDelete) return;

    try {
      // Call the delete API
      const response = await axios.post(
        "https://uatapi.policicue.com/hrms/deleteEmployee",
        {
          employee_id: employeeId,
        }
      );

      if (response.status === 200) {
        alert("Employee deleted successfully!");

        // Update the state to remove the deleted employee
        const updatedData = data.filter(
          (member) => member.employeeData.employee_id !== employeeId
        );
        setData(updatedData);
        setFilteredData(updatedData);
      } else {
        alert("Failed to delete the employee. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
      alert("An error occurred while deleting the employee.");
    }
  };

  return (
    <div className="ListMembers">
      <div className="container-fluid my-2">
        <div className="row">
          <main className="mainBodyContainer">
            <div className="bg-white p-2 rounded-3 shadow-sm">
              <div className="d-flex justify-content-between">
                <h4
                  className="text-capitalize"
                  style={{ color: "#3a3285", padding: "5px" }}
                >
                  List of Employees
                </h4>
              </div>
              <div className="icueSearchBox w-100">
                <div
                  className="input-group mb-3"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span className="input-group-text" id="basic-addon1">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    className=" search form-control"
                    placeholder="Search Users by Name, Email "
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{ backgroundColor: "#F4F7FC" }}
                  />
                </div>
              </div>
              {loading ? (
                <p>Loading members...</p>
              ) : error ? (
                <p className="text-danger">{error}</p>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover results">
                    <thead>
                      <tr
                        className="text-uppercase"
                        style={{
                          fontFamily: "Calibri",
                          fontStyle: "normal",
                          fontSize: "12px",
                          backgroundColor: "#F4F7FC",
                        }}
                      >
                        <th className="py-3">ID</th>
                        <th className="py-3">NAME</th>
                        <th className="py-3 d-none d-md-table-cell">EMAIL</th>
                        <th className="py-3">JOINING POSITION</th>
                        <th className="py-3 d-none d-lg-table-cell">
                          DEPARTMENT
                        </th>
                        <th className="py-3">JOINED ON</th>
                        <th className="py-3">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontFamily: "Calibri", fontSize: "14px" }}>
                      {filteredData.map((member, index) => {
                        const {
                          employee_id,
                          first_name,
                          last_name,
                          email,
                          joining_date,
                          joining_position,
                          department,
                        } = member.employeeData;
                        return (
                          <tr
                            key={member.id}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#FFFFFF" : "#F4F7FC",
                            }}
                          >
                            <td>{employee_id}</td>
                            <td>{`${first_name} ${last_name}`}</td>
                            <td className="d-none d-md-table-cell">{email}</td>
                            <td>{joining_position}</td>
                            <td className="d-none d-lg-table-cell">
                              {department}
                            </td>
                            <td>
                              {new Date(joining_date).toLocaleDateString()}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-primary btn-sm btn-transparent"
                                onClick={() =>
                                  handleViewMore(member.employeeData)
                                }
                              >
                                View More
                              </button>
                              <button
                                className="btn btn-danger btn-sm ms-2"
                                onClick={() =>
                                  handleDelete(member.employeeData.employee_id)
                                }
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>

      {/* Modal */}

      {showModal && selectedEmployee && (
        <div
          className="modal-overlay"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 1000,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              position: "relative",
              width: "100%",
              height: "80%",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "12px",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
              border: "none",
              overflowY: "auto",
            }}
          >
            <button
              className="close-btn"
              onClick={closeModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "1.5rem",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "#aaa",
              }}
              title="Close"
            >
              &times;
            </button>
            <h5
              style={{
                fontFamily: "Arial, sans-serif",
                fontWeight: "bold",
                color: "#333",
                marginBottom: "20px",
              }}
            >
              Employee Details
            </h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Employee ID</th>
                  <td>{selectedEmployee.employee_id}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{`${selectedEmployee.first_name} ${selectedEmployee.last_name}`}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{selectedEmployee.email}</td>
                </tr>
                <tr>
                  <th>Joining Position</th>
                  <td>{selectedEmployee.joining_position}</td>
                </tr>
                <tr>
                  <th>Department</th>
                  <td>{selectedEmployee.department}</td>
                </tr>
                <tr>
                  <th>Joining Date</th>
                  <td>
                    {new Date(
                      selectedEmployee.joining_date
                    ).toLocaleDateString()}
                  </td>
                </tr>
                <tr>
                  <th>reporting_manager</th>
                  <td>{selectedEmployee.reporting_manager}</td>
                </tr>
                <tr>
                  <th>Work Location</th>
                  <td>{selectedEmployee.work_location}</td>
                </tr>
                <tr>
                  <th>Employee Type</th>
                  <td>{selectedEmployee.employee_type}</td>
                </tr>
                <tr>
                  <th>Shift Timing</th>
                  <td>{selectedEmployee.shift_timing}</td>
                </tr>
                <tr>
                  <th>Contact Number</th>
                  <td>{selectedEmployee.contact_number}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{selectedEmployee.city}</td>
                </tr>
                <tr>
                  <th>State</th>
                  <td>{selectedEmployee.state}</td>
                </tr>
                <tr>
                  <th>Country</th>
                  <td>{selectedEmployee.country}</td>
                </tr>
                <tr>
                  <th>pincode</th>
                  <td>{selectedEmployee.pincode}</td>
                </tr>
                <tr>
                  <th>Emergency Contact</th>
                  <td>{selectedEmployee.emergency_contact_number}</td>
                </tr>
                <tr>
                  <th>Date of Birth</th>
                  <td>{new Date(selectedEmployee.dob).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{selectedEmployee.gender}</td>
                </tr>
                <tr>
                  <th>Marital Status</th>
                  <td>{selectedEmployee.marital_status}</td>
                </tr>
              </tbody>
            </table>
            <h5
              style={{
                fontFamily: "Arial, sans-serif",
                fontWeight: "bold",
                color: "#333",
                marginBottom: "20px",
              }}
            >
              Educational Details
            </h5>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <th>Highest Qualification</th>
                  <td>{selectedEmployee.highest_qualification}</td>
                </tr>
                <tr>
                  <th>Institute/University</th>
                  <td>{selectedEmployee.institute_university_name}</td>
                </tr>
                <tr>
                  <th>Year of Graduation</th>
                  <td>{selectedEmployee.year_of_graduation}</td>
                </tr>
                <tr>
                  <th>Board of Education</th>
                  <td>{selectedEmployee.board_of_education}</td>
                </tr>
                <tr>
                  <th>Class 10 Percentage</th>
                  <td>{selectedEmployee.calss_10_percentage}</td>
                </tr>{" "}
                <tr>
                  <th>Class 12 Percentage</th>
                  <td>{selectedEmployee.class_12_percentage}</td>
                </tr>
                <tr>
                  <th>Class 12 Percentage</th>
                  <td>{selectedEmployee.class_12_percentage}</td>
                </tr>
                <tr>
                  <th>Class 12 stream</th>
                  <td>{selectedEmployee.class_12_stream}</td>
                </tr>
                <tr>
                  <th>Skills Acquired</th>
                  <td>{selectedEmployee.skill_acquired}</td>
                </tr>{" "}
                <tr>
                  <th>Additional Courses</th>
                  <td>{selectedEmployee.additional_courses}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="pagination mt-3">
          <button
            onClick={handlePreviousPage}
            style={{
              fontWeight: "bold",
              cursor: currentPage > 1 ? "pointer" : "not-allowed",
            }}
            disabled={currentPage === 1}
            className="btn btn-outline-primary btn-sm mx-1"
          >
            <i className="bi bi-arrow-left"></i> Previous
          </button>
          <span style={{ margin: "0 10px" }}>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            style={{
              fontWeight: "bold",
              cursor: currentPage < totalPages ? "pointer" : "not-allowed",
            }}
            disabled={currentPage === totalPages}
            className="btn btn-outline-primary btn-sm mx-1"
          >
            Next <i className="bi bi-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
