import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import AddPOS from "../pages/AddPOS";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import POSDetails from "../pages/POSDetails";
import ViewPOS from "../pages/ViewPOS";
import AddCattleInsurance from "../pages/AddCattleInfo";
import CattleInsuranceDetails from "../pages/CattleInsuranceDetails";
import ViewCattleDetails from "../pages/ViewCattleDetails";
import EditCattleDetails from "../pages/EditCattleDetails";
import ShareDetails from "../pages/ShareDetails";
import EndorsementDetails from "../pages/EndorsementDetails";
import ClaimDetails from "../pages/ClaimDetails";
import AddIntimateClaim from "../pages/AddIntimateClaim";
import ViewClaimDetails from "../pages/ViewClaimDetails";
import EditClaimDetails from "../pages/EditClaimDetails";
import DoctorManagement from "../pages/DoctorManagement";
import InvestigatorManagement from "../pages/InvestigatorManagement";
import RoleDetails from "../pages/RoleDetails";
import RolePermissionDetails from "../pages/RolePermissionDetails";
import AssignedCattleDoctor from "../pages/AssignedCattleDoctor";
import AssignedCattleInvestigator from "../pages/AssignedCattleInvestigator";
import DoctorRequestList from "../pages/DoctorRequestList";
import InvestigatorRequestList from "../pages/InvestigatorRequestList";
import SurveyorList from "../pages/SurveyorManagement";
import SurveyorRequestList from "../pages/SurveyorRequestList";
import AssignedCattleSurveyor from "../pages/AssignedCattleSurveyor";
import HRDashboard from "../pages/HRMSDashboard.js";
import Onboard from "../pages/Onboarding/Onboard.js";
import OnboardList from "../pages/Onboarding/OnboardList.js";
import ListMembers from "../pages/UserManagement/ListMembers.js";
import AddMemberPage from "../pages/UserManagement/AddMemberPage.js";
import RequestedLeaves from "../pages/Leavemanagement/RequestedLeaves.js";
import RejectedLeaves from "../pages/Leavemanagement/RejectedLeaves.js";
import ApprovedLeaves from "../pages/Leavemanagement/ApprovedLeaves.js";
import AddDepartments from "../pages/Department/Department.js";
import AddRoles from "../pages/Roles/Roles.js";
import Holidays from "../hooks/Holidays/Holiday.js";
import Attendance from "../pages/Attendance/Attendance.js";
import Payroll from "../pages/Payroll/Payroll.js";
const AppRoutes = () => {
  return (
    <Layout>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/pos/all" element={<POSDetails />} />
        <Route exact path="/pos/add" element={<AddPOS />} />
        <Route exact path="/pos/:id/view" element={<ViewPOS />} />
        <Route
          exact
          path="/cattle-insurance/all"
          element={<CattleInsuranceDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/add"
          element={<AddCattleInsurance />}
        />
        <Route exact path="/cattle/:id/view" element={<ViewCattleDetails />} />
        <Route exact path="/cattle/:id/edit" element={<EditCattleDetails />} />
        <Route
          exact
          path="/cattle-insurance/insurer-details"
          element={<ShareDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/insurer-details/:id"
          element={<ShareDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/endorsement"
          element={<EndorsementDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/claim"
          element={<ClaimDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/intimate-claim"
          element={<AddIntimateClaim />}
        />
        <Route exact path="/claim/:id/view" element={<ViewClaimDetails />} />
        <Route exact path="/claim/:id/edit" element={<EditClaimDetails />} />
        <Route
          exact
          path="/cattle-insurance/doctor"
          element={<DoctorManagement />}
        />
        <Route
          exact
          path="/cattle-insurance/investigator"
          element={<InvestigatorManagement />}
        />
        <Route exact path="/cattle-insurance/role" element={<RoleDetails />} />
        <Route
          exact
          path="/cattle-insurance/role-permission"
          element={<RolePermissionDetails />}
        />
        <Route
          exact
          path="/cattle-insurance/assigned-doctor"
          element={<AssignedCattleDoctor />}
        />
        <Route
          exact
          path="/cattle-insurance/assigned-investigator"
          element={<AssignedCattleInvestigator />}
        />
        <Route
          exact
          path="/cattle-insurance/assigned-surveyor"
          element={<AssignedCattleSurveyor />}
        />
        <Route
          exact
          path="/cattle-insurance/doctor-dashboard"
          element={<DoctorRequestList />}
        />
        <Route
          exact
          path="/cattle-insurance/investigator-dashboard"
          element={<InvestigatorRequestList />}
        />
        <Route
          exact
          path="/cattle-insurance/surveyor-dashboard"
          element={<SurveyorRequestList />}
        />
        <Route
          exact
          path="/cattle-insurance/surveyor"
          element={<SurveyorList />}
        />
        <Route exact path="/hrms/dashboard" element={<HRDashboard />} />
        <Route
          exact
          path="hrms/Onboarding/Add-Employee"
          element={<Onboard />}
        />
        <Route
          exact
          path="hrms/Onboarding/OnboardList"
          element={<OnboardList />}
        />
        <Route
          exact
          path="/hrms/user-management/list-members"
          element={<ListMembers />}
        />
        <Route
          exact
          path="/hrms/user-management/add-member"
          element={<AddMemberPage />}
        />

        <Route
          exact
          path="/hrms/Leave-management/Requested-Leaves"
          element={<RequestedLeaves />}
        />
        <Route
          exact
          path="/hrms/Leave-management/Rejected-Leaves"
          element={<RejectedLeaves />}
        />
        <Route
          exact
          path="/hrms/Leave-management/Approved-Leaves"
          element={<ApprovedLeaves />}
        />
        <Route exact path="/hrms/Department" element={<AddDepartments />} />
        <Route exact path="/hrms/Roles" element={<AddRoles />} />
        <Route exact path="/hrms/Holidays" element={<Holidays />} />
        <Route exact path="/hrms/Attendance" element={<Attendance />} />
        <Route exact path="/hrms/payroll" element={<Payroll />} />
      </Routes>
    </Layout>
  );
};

export default AppRoutes;
