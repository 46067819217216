import React, { useEffect, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import { format } from "date-fns";
import Constant from "../../utils/constant";
export default function Attendance() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [uniqueEmployees, setUniqueEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  // Fetching attendance data and leave data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch attendance data
        const attendanceResponse = await axios.post(
          Constant.BASE_URL + "hrms/getEmployeeAttendance?page=1&limit=100"
        );
        const attendance = attendanceResponse.data.data || [];

        // Fetch leave data
        const leaveResponse = await axios.post(
          Constant.BASE_URL + "hrms/requestedLeaves?page=1&limit=10"
        );
        const leaves = leaveResponse.data.data || [];

        // Mark attendance status as "On Leave" if the employee is on leave
        const updatedAttendance = attendance.map((attendanceRecord) => {
          const employeeLeaves = leaves.filter((leave) => {
            return (
              leave.employee_code === attendanceRecord.employee_id &&
              new Date(leave.leave_from) <=
                new Date(attendanceRecord.check_in_datetime) &&
              new Date(leave.leave_to) >=
                new Date(attendanceRecord.check_out_datetime)
            );
          });

          if (
            employeeLeaves.length > 0 &&
            employeeLeaves[0].leave_status === "Approved"
          ) {
            return { ...attendanceRecord, status: "On Leave" };
          }
          return attendanceRecord;
        });

        // Filter unique employees
        const unique = updatedAttendance.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.employee_id === item.employee_id)
        );

        setAttendanceData(updatedAttendance);
        setUniqueEmployees(unique);
        setLeaveData(leaves);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching attendance or leave data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewMore = (employee) => {
    const employeeRecords = attendanceData.filter(
      (record) => record.employee_id === employee.employee_id
    );

    setSelectedEmployee(employee);
    filterModalDataByMonth(employeeRecords, selectedMonth);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData([]);
  };

  const filterModalDataByMonth = (data, month) => {
    const filteredData = data.filter((record) => {
      const recordDate = new Date(record.check_in_datetime);
      return recordDate.getMonth() === month;
    });
    setModalData(filteredData);
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value, 10);
    setSelectedMonth(newMonth);
    if (selectedEmployee) {
      const employeeRecords = attendanceData.filter(
        (record) => record.employee_id === selectedEmployee.employee_id
      );
      filterModalDataByMonth(employeeRecords, newMonth);
    }
  };

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: 1000,
    },
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      height: "80%",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
      border: "none",
      overflowY: "auto",
    },
  };

  return (
    <div className="Attendance">
      <div className="container-fluid my-2">
        <div className="row">
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-12 py-2 px-3">
                <h4
                  className="text-capitalize"
                  style={{
                    fontFamily: "ErasDemiITC",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginLeft: "12px",
                  }}
                >
                  Attendance
                </h4>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12 py-1 px-3">
                <div className="bg-white p-2 rounded-3 shadow-sm">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <table
                      className="table table-striped table-hover"
                      width="auto"
                    >
                      <thead>
                        <tr
                          className="text-uppercase"
                          style={{
                            lineHeight: "15px",
                            fontFamily: "Calibri",
                            fontSize: "14px",
                            color: "#7D90B2",
                            backgroundColor: "#F4F7FC",
                          }}
                        >
                          <th className="py-3">Employee</th>
                          <th className="text-center py-3">Date</th>
                          <th className="text-center py-3">Check In Time</th>
                          <th className="text-center py-3">Check Out Time </th>
                          <th className="text-center py-3">Work Hours</th>

                          <th className="text-center py-3">Status</th>
                          <th className="text-center py-3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueEmployees.map((employee, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {employee.employee_name}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#7D90B2",
                                  }}
                                >
                                  Emp ID: {employee.employee_id}
                                </span>
                              </div>
                            </td>
                            <td className="text-center">
                              {employee.date
                                ? format(new Date(employee.date), "dd/MM/yyyy")
                                : "-"}
                            </td>
                            <td className="text-center">
                              {employee.check_in_datetime || "-"}
                            </td>
                            <td className="text-center">
                              {employee.check_out_datetime || "-"}
                            </td>
                            <td className="text-center">
                              {employee.work_hours || "-"}
                            </td>
                            <td className="text-center">
                              {employee.status || "-"}
                            </td>
                            <td className="text-center">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleViewMore(employee)}
                              >
                                View More
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {/* Modal for Detailed Attendance */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #eee",
            paddingBottom: "10px",
          }}
        >
          <h4
            style={{
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            Attendance Details for {selectedEmployee?.employee_name} (Emp ID:{" "}
            {selectedEmployee?.employee_id})
          </h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              style={{
                marginRight: "10px",
                padding: "5px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index}>
                  {format(new Date(0, index), "MMMM")}
                </option>
              ))}
            </select>
            <button
              className="btn-close"
              onClick={closeModal}
              style={{
                fontSize: "1.5rem",
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "#aaa",
              }}
              title="Close"
            >
              ×
            </button>
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <table className="table table-striped table-bordered">
            <thead style={{ backgroundColor: "#f8f9fa", color: "#333" }}>
              <tr>
                <th>Date</th>
                <th>Check-in</th>
                <th>Check-out</th>
                <th>Work Hours</th>
                <th>Overtime</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((day, index) => (
                <tr key={index}>
                  <td>
                    {day.date
                      ? format(new Date(day.check_in_datetime), "dd/MM/yyyy")
                      : "-"}
                  </td>
                  <td>{day.check_in_datetime || "-"}</td>
                  <td>{day.check_out_datetime || "-"}</td>
                  <td>{day.work_hours || "-"}</td>
                  <td>{day.overtime_hours || "-"}</td>
                  <td>{day.status || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}
