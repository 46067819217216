import React, { useEffect, useState } from "react";
import axios from "axios";
import Constant from "../../utils/constant";
export default function Payroll() {
  const [payrollData, setPayrollData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayrollData = async () => {
      try {
        const response = await axios.post(
          Constant.BASE_URL + "hrms/getEmployeePayroll?page=1&limit=10"
        );
        setPayrollData(response.data.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching payroll data:", error);
        setLoading(false);
      }
    };

    fetchPayrollData();
  }, []);

  return (
    <div className="Payroll">
      <div className="container-fluid my-2">
        <div className="row">
          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-12 py-2 px-3">
                <h4
                  className="text-capitalize"
                  style={{
                    fontFamily: "ErasDemiITC",
                    fontWeight: "bold",
                    marginTop: "1rem",
                    marginLeft: "12px",
                  }}
                >
                  Payroll
                </h4>
              </div>
            </div>
            <div className="row my-2">
              <div className="col-12 py-1 px-3">
                <div className="bg-white p-2 rounded-3 shadow-sm">
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <table
                      className="table table-striped table-hover"
                      width="auto"
                    >
                      <thead>
                        <tr
                          className="text-uppercase"
                          style={{
                            lineHeight: "15px",
                            fontFamily: "Calibri",
                            fontSize: "14px",
                            color: "#7D90B2",
                            backgroundColor: "#F4F7FC",
                          }}
                        >
                          <th className="py-3">Employee</th>
                          <th className="text-center py-3">Base Salary</th>
                          <th className="text-center py-3">Work Days</th>
                          <th className="text-center py-3">Leave Days</th>
                          <th className="text-center py-3">Overtime (Hrs)</th>
                          <th className="text-center py-3">Gross Pay</th>
                          <th className="text-center py-3">Deductions</th>
                          <th className="text-center py-3">Net Pay</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payrollData.map((employee, index) => (
                          <tr key={index}>
                            <td>
                              <div>
                                {employee.employee_name}
                                <br />
                                <span
                                  style={{
                                    fontSize: "12px",
                                    color: "#7D90B2",
                                  }}
                                >
                                  Emp ID: {employee.employee_id}
                                </span>
                              </div>
                            </td>
                            <td className="text-center">
                              ₹{employee.base_salary.toLocaleString()}
                            </td>
                            <td className="text-center">
                              {employee.work_days}
                            </td>
                            <td className="text-center">
                              {employee.leave_days}
                            </td>
                            <td className="text-center">
                              {employee.overtime_hours}
                            </td>
                            <td className="text-center">
                              ₹{employee.gross_pay.toLocaleString()}
                            </td>
                            <td className="text-center">
                              ₹{employee.deductions.toLocaleString()}
                            </td>
                            <td className="text-center">
                              ₹{employee.net_pay.toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
