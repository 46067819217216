import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RequestedLeaves.css";
// import Topbar from "../../../../Dashboard";
// import ApprovedLeaves from "./ApprovedLeaves";
// import Leftdashboard from "../../../../Leftdashboard";
import Constant from "../../utils/constant";
export default function RequestedLeaves() {
  const [reqdata, setReqdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    // Fetching data from the API
    const fetchData = async () => {
      try {
        const response = await axios.post(
          Constant.BASE_URL + "hrms/requestedLeaves?page=1&limit=10"
        );
        setReqdata(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to load requested leaves.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const updateLeaveStatus = async (id, status) => {
    try {
      await axios.post(Constant.BASE_URL + "hrms/updateLeaveStatus", {
        id,
        leave_status: status,
      });
      setReqdata((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, leave_status: status } : item
        )
      );
    } catch (err) {
      console.error("Error updating leave status:", err);
      alert("Failed to update leave status.");
    }
  };

  // Filter data based on leave status
  const approvedLeaves = reqdata.filter(
    (item) => item.leave_status === "Approved"
  );
  const rejectedLeaves = reqdata.filter(
    (item) => item.leave_status === "Rejected"
  );

  // Implement search functionality
  const filteredData = reqdata.filter(
    (item) =>
      item.employee_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.leave_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.leave_status.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="Leaves">
      {/* Top Bar */}
      {/* <Topbar /> */}

      {/* Main Body */}
      <div className="container-fluid my-2">
        <div className="row">
          {/* <Leftdashboard /> */}

          <main className="mainBodyContainer">
            <div className="row my-2">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-2 px-3">
                <div className="d-flex justify-content-between">
                  <h4
                    className="text-captalize"
                    style={{
                      fontFamily: "ErasDemiITC",
                      fontWeight: "bold",
                      marginTop: "1rem",
                      marginLeft: "12px",
                    }}
                  >
                    Requested Leaves
                  </h4>
                  <div className="bg-transparent p-2 rounded-3">
                    <nav
                      style={{
                        bsbreadcrumbdivider:
                          'url(&#34;"data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="8" height="8"%3E%3Cpath d="M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z" fill="currentColor"/%3E%3C/svg%3E&#34")',
                      }}
                      aria-label="breadcrumb"
                    >
                      <ol className="breadcrumb mb-0 justify-content-end">
                        <li
                          className="breadcrumb-item bg-transparent"
                          style={{ fontFamily: "Calibri", fontSize: "bold" }}
                        >
                          <a
                            href="#/hrms/dashboard"
                            className="text-decoration-none text-info"
                          >
                            Dashboard
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item bg-transparent"
                          style={{ fontFamily: "Calibri" }}
                        >
                          <a
                            href="#/hrms/dashboard"
                            className="text-decoration-none text-info"
                          >
                            Leave Management
                          </a>
                        </li>
                        <li
                          className="breadcrumb-item text-muted text-capitalize bg-transparent"
                          aria-current="page"
                          style={{ fontSize: "bold" }}
                        >
                          Requested Leaves
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <div className="row my-2">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-1 px-3">
                <div className="bg-white p-2 rounded-3 shadow-sm">
                  <div className="px-2 py-3">
                    <div className="container1">
                      <div className="row">
                        <div className="col-xs-12 bg-white d-flex justify-content-between">
                          {/* Search and Add Employee section */}
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icueSearchBox w-100 mx-3">
                              <div
                                className="input-group"
                                style={{
                                  marginLeft: "-30px",
                                  marginBottom: "10px",
                                }}
                              >
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <i className="bi bi-search"></i>
                                </span>
                                <input
                                  type="search"
                                  value={searchQuery}
                                  className="search form-control icueSearchBoxControl"
                                  placeholder="Search Users by Name"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  style={{ backgroundColor: "#F4F7FC" }}
                                  onChange={(e) =>
                                    setSearchQuery(e.target.value)
                                  } // Add this line
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div className="icueSearchBox w-75 mx-3">
                              {/* <div
                                className="search input-group"
                                style={{ marginLeft: "120px" }}
                              >
                                <a
                                  href=""
                                  type="button"
                                  className="icueBgPrimary text-white pt-2 ms-auto py-2"
                                  style={{
                                    width: "150px",
                                    textAlign: "center",
                                    fontSize: "15px",
                                    borderRadius: "4px",
                                    // float: "right",
                                    // position: "absolute",
                                  }}
                                >
                                  <i className="bi bi-plus-circle"></i>{" "}
                                  &nbsp;Add Employee
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                          <div
                            className="tab-content py-1 px-3 px-sm-0"
                            id="myTabContent"
                          >
                            <div
                              className="tab-pane fade show active"
                              id="home"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <div className="row">
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  bg-white">
                                  <table
                                    id="payrollTable"
                                    className="table table-hover results"
                                    width="auto"
                                  >
                                    <thead>
                                      <tr
                                        className="text-uppercase"
                                        style={{
                                          lineHeight: "15px",
                                          fontFamily: "Calibri",
                                          fontStyle: "normal",
                                          fontSize: "12px",
                                          backgroundColor: "#F4F7FC",
                                        }}
                                      >
                                        <th className="py-3">EMP.CODE</th>
                                        <th className="py-3">EMP.NAME</th>
                                        <th className="py-3">LEAVE TYPE</th>
                                        <th className="py-3">FROM</th>
                                        <th className="py-3 hide-on-mobile">
                                          TO
                                        </th>{" "}
                                        {/* Add a class here */}
                                        <th className="py-3">NO. OF DAYS</th>
                                        <th className="py-3">REASON</th>
                                        <th className="py-3">LEAVE STATUS</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading ? (
                                        <tr>
                                          <td colSpan="8">Loading...</td>
                                        </tr>
                                      ) : error ? (
                                        <tr>
                                          <td
                                            colSpan="8"
                                            className="text-danger"
                                          >
                                            {error}
                                          </td>
                                        </tr>
                                      ) : (
                                        filteredData.map((row, index) => (
                                          <tr
                                            key={row.id}
                                            style={{
                                              border: "0",
                                              backgroundColor:
                                                index % 2 === 0
                                                  ? "#FFFFFF"
                                                  : "#F4F7FC",
                                            }}
                                          >
                                            <td>{row.employee_code}</td>
                                            <td>{row.employee_name}</td>
                                            <td>{row.leave_type}</td>
                                            <td>
                                              {new Date(
                                                row.leave_from
                                              ).toLocaleDateString()}
                                            </td>
                                            <td className="hide-on-mobile">
                                              {new Date(
                                                row.leave_to
                                              ).toLocaleDateString()}
                                            </td>
                                            <td>{row.no_of_days}</td>
                                            <td>{row.leave_reason}</td>
                                            <td>
                                              <i
                                                className="bi bi-check-circle fa-lg"
                                                style={{
                                                  color:
                                                    row.leave_status ===
                                                    "Approved"
                                                      ? "#29CC97"
                                                      : "#D3D3D3",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  updateLeaveStatus(
                                                    row.id,
                                                    "Approved"
                                                  )
                                                }
                                              ></i>
                                              <i
                                                className="bi bi-x-circle fa-lg"
                                                style={{
                                                  color:
                                                    row.leave_status ===
                                                    "Rejected"
                                                      ? "#D64343"
                                                      : "#D3D3D3",
                                                  marginLeft: "20px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  updateLeaveStatus(
                                                    row.id,
                                                    "Rejected"
                                                  )
                                                }
                                              ></i>
                                            </td>
                                          </tr>
                                        ))
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
